import { handleActions } from 'redux-actions'

import actions from '@/actions'

import lecturesReducer from './reducers/lectures.reducer'
import progressReducer from './reducers/progress.reducer'

const initialState = {}

export default handleActions(
  {
    ...lecturesReducer,
    ...progressReducer,
    [actions.lecture.clearAll]: () => initialState,
  },
  initialState,
)
