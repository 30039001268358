import { useQuery } from '@tanstack/react-query'

import { topicsQueries } from '@/http/api/topics'

interface Args {
  enabled?: boolean
  slug?: string
}

export function useTopicDetails({ slug = '', enabled = true }: Args) {
  return useQuery({
    enabled,
    ...topicsQueries.details(slug),
  })
}
