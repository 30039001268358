import { Topic } from '@/api/generated-api-and-types'

import { createApiMethod, type } from './utils/createApiMethod'

export const topics = {
  details: createApiMethod({
    method: 'GET',
    path: '/topics/:slug',
    response: type<Topic>(),
  }),
}

export const topicsQueries = {
  all: () => ['topics'] as const,
  details: (slug: string) => ({
    queryKey: [...topicsQueries.all(), slug] as const,
    queryFn: () => topics.details({ params: { slug } }),
  }),
}
