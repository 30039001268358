import { withProfiler } from '@sentry/react'
import { Suspense, useEffect } from 'react'
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom'
import { Userpilot } from 'userpilot'

import { useAuth } from '@/auth/hooks/useAuth'
import { NoEmailLoginRedirect } from '@/company/components/NoEmailLoginRedirect/NoEmailLoginRedirect'
import { NoEmailRegisterRedirect } from '@/company/components/NoEmailRegisterRedirect/NoEmailRegisterRedirect'
import { Consents } from '@/consents/components/Consents'
import { PageNotFound } from '@/core/components/PageNotFound/PageNotFound'
import Loader from '@/core/components/ui/Loader/Loader'
import {
  URL_ACHIEVEMENTS,
  URL_CERTIFICATE,
  URL_CERTIFICATES,
  URL_COMPANY,
  URL_CONSENTS,
  URL_CORE_SKILLS_ASSESSMENT,
  URL_CORE_SKILLS_ASSESSMENT_DEPRECATED,
  URL_COURSE,
  URL_COURSE_GENERIC,
  URL_DASHBOARD,
  URL_DISCOVER,
  URL_GLOSSARY,
  URL_GROWTH_MAP,
  URL_LEARNGROUP,
  URL_LEARNPATHS,
  URL_LEARNPATHS_AS_COURSES,
  URL_LECTURES,
  URL_LOGIN,
  URL_NO_EMAIL_LOGIN,
  URL_NO_EMAIL_REGISTER,
  URL_POLL_PAGE,
  URL_ROOT,
  URL_SIGNUP_CREDENTIALS,
  URL_SIGNUP_EMAIL,
  URL_SIGNUP_POLL,
  URL_SIGNUP_PROFILE,
  URL_SIGNUP_PROFILE_AVATAR,
  URL_SIGNUP_SELF,
  URL_SIGNUP_WELCOME,
  URL_SKILL,
  URL_TOPIC,
  URL_USER,
  URL_USER_PROGRESS,
} from '@/core/constants/constants'
import { lazyLoad } from '@/core/utils/lazyLoad'
import { KeycloakLogin } from '@/user/components/KeycloakLogin/KeycloakLogin'

import { AppManagerFetch } from './AppManagerFetch'
import { AppManagerIdentify } from './AppManagerIdentify'
import { AppManagerRedirect } from './AppManagerRedirect'
import { AppManagerSSO } from './AppManagerSSO'
import { AppManagerScorm } from './AppManagerScorm'
import { AppManagerTitle } from './AppManagerTitle'

const StudentApp = lazyLoad(async () => ({
  default: (await import('./student/StudentApp')).ProfiledStudentApp,
}))
const ManagerApp = lazyLoad(async () => ({
  default: (await import('./manager/ManagerApp')).ProfiledManagerApp,
}))
const Signup = lazyLoad(() => import('@/signup/components/Signup'))
const CoreSkillsAssessmentRouter = lazyLoad(() =>
  import(
    '@/core-skills-assessment/components/CoreSkillsAssessmentRouter/CoreSkillsAssessmentRouter'
  ).then((module) => ({ default: module.CoreSkillsAssessmentRouter })),
)

function LocationListener() {
  const location = useLocation()

  useEffect(() => {
    Userpilot.reload()
  }, [location.pathname])

  return null
}

type ProtectedRouteProps = RouteProps & {
  access: 'authenticated' | 'unauthenticated' | 'all'
}

/**
 *  Wrapper for Route component that checks if user is authenticated
 */
function ProtectedRoute({
  children,
  access,
  ...routeProps
}: ProtectedRouteProps) {
  const { isAuthenticated, login } = useAuth()

  if (isAuthenticated === undefined) {
    return null
  }

  if (access === 'authenticated' && isAuthenticated === false) {
    login()
    return null
  }

  if (access === 'unauthenticated' && isAuthenticated === true) {
    return <Redirect to={URL_DASHBOARD} />
  }

  return <Route {...routeProps}>{children}</Route>
}

function AppRouterWithoutProfiler() {
  return (
    <>
      <LocationListener />
      <Switch>
        <ProtectedRoute access="all" exact path="/crash">
          {() => {
            // trigger a on-demand crash to test and see fallback
            throw new Error('Test Crash!')
          }}
        </ProtectedRoute>
        <ProtectedRoute access="all" path={URL_NO_EMAIL_LOGIN}>
          <NoEmailLoginRedirect />
        </ProtectedRoute>
        <ProtectedRoute access="all" path={URL_NO_EMAIL_REGISTER}>
          <NoEmailRegisterRedirect />
        </ProtectedRoute>
        <Route>
          <AppManagerSSO>
            <AppManagerFetch>
              <AppManagerTitle>
                <AppManagerScorm>
                  <AppManagerIdentify>
                    <AppManagerRedirect>
                      <Suspense fallback={<Loader fixed />}>
                        <Switch>
                          <Redirect exact from={URL_ROOT} to={URL_DASHBOARD} />
                          <ProtectedRoute
                            access="unauthenticated"
                            path={URL_LOGIN}>
                            <KeycloakLogin />
                          </ProtectedRoute>
                          <ProtectedRoute access="all" path={URL_CONSENTS}>
                            <Consents />
                          </ProtectedRoute>
                          <ProtectedRoute
                            access="authenticated"
                            path={[
                              URL_ACHIEVEMENTS,
                              URL_CERTIFICATE,
                              URL_CERTIFICATES,
                              URL_DASHBOARD,
                              URL_DISCOVER,
                              URL_GLOSSARY,
                              URL_COURSE,
                              URL_COURSE_GENERIC,
                              URL_GROWTH_MAP,
                              URL_LECTURES,
                              URL_LEARNGROUP,
                              URL_LEARNPATHS,
                              URL_LEARNPATHS_AS_COURSES,
                              URL_SKILL,
                              URL_USER,
                              URL_USER_PROGRESS,
                              URL_TOPIC,
                              URL_POLL_PAGE,
                            ]}>
                            <StudentApp />
                          </ProtectedRoute>
                          <ProtectedRoute
                            access="authenticated"
                            path={URL_COMPANY}>
                            <ManagerApp />
                          </ProtectedRoute>
                          <ProtectedRoute
                            access="all"
                            path={[URL_SIGNUP_WELCOME]}>
                            <Signup />
                          </ProtectedRoute>
                          <ProtectedRoute
                            access="authenticated"
                            path={[
                              URL_SIGNUP_EMAIL,
                              URL_SIGNUP_PROFILE,
                              URL_SIGNUP_PROFILE_AVATAR,
                              URL_SIGNUP_POLL,
                            ]}>
                            <Signup />
                          </ProtectedRoute>
                          <ProtectedRoute
                            access="unauthenticated"
                            path={[URL_SIGNUP_CREDENTIALS, URL_SIGNUP_SELF]}>
                            <Signup />
                          </ProtectedRoute>
                          <ProtectedRoute
                            access="authenticated"
                            path={[
                              URL_CORE_SKILLS_ASSESSMENT,
                              URL_CORE_SKILLS_ASSESSMENT_DEPRECATED,
                            ]}>
                            <CoreSkillsAssessmentRouter />
                          </ProtectedRoute>
                          <ProtectedRoute access="all" path="*">
                            <PageNotFound />
                          </ProtectedRoute>
                        </Switch>
                      </Suspense>
                    </AppManagerRedirect>
                  </AppManagerIdentify>
                </AppManagerScorm>
              </AppManagerTitle>
            </AppManagerFetch>
          </AppManagerSSO>
        </Route>
      </Switch>
    </>
  )
}

export const AppRouter = withProfiler(AppRouterWithoutProfiler)
