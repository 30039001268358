import {
  defaultTo,
  filter,
  findIndex,
  first,
  get,
  getOr,
  identity,
  includes,
  isEmpty,
  last,
  map,
  mergeAll,
  omit,
  pipe,
  size,
  split,
  toNumber,
} from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { everyTrue, passProps, silentAttempt } from '@masterplandev/utils'

import { STATUS_IN_PROGRESS, STATUS_STARTED } from '@/core/constants/constants'
import { ParametricResult } from '@/core/selectors.types'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import cachedKeyCreator from '@/core/utils/redux/cachedKeyCreator'
import createMemoizeByIdentifierSelector from '@/core/utils/redux/createMemoizeByIdentifierSelector'
import mergeState from '@/core/utils/redux/mergeState'
import {
  assignedElementListSelector,
  assignedElementSelectors,
  assignedLearnpathDurationSelector,
  assignedLearnpathIsLinearSelector,
  assignedLearnpathIsMandatorySelector,
  assignedLearnpathSelectors,
  assignedLearnpathsRootSelector,
  assignedLectureSelectors,
  assignedNextElementUrlSelector,
} from '@/learnpaths-assigned/selectors'

import {
  LECTURE_IMAGE_ID_EVENT,
  LECTURE_IMAGE_ID_FILE,
  LECTURE_IMAGE_ID_LINK,
  LECTURE_IMAGE_ID_SCORM,
  LECTURE_TYPE_EMBEDDED_VIDEO,
  LECTURE_TYPE_TEXT,
  LECTURE_TYPE_VIDEO,
} from '../constants'
import { UniversalLectureType } from '../types/UniversalLectureType'
import buildUniversalLectureApiUrl from '../utils/buildUniversalLectureApiUrl'
import toMatchParams from '../utils/toMatchParams'
import {
  lectureSelectors,
  lectureTranscriptSelector,
  nextLectureUrlSelector,
  rootSelector,
} from './lecture.selectors'

export const universalLectureMotifTitleSelector = createCachedSelector(
  [assignedLearnpathSelectors.data, passProps],
  (assignedLearnpathData, { learnpathId, topicData }) => {
    return learnpathId ? assignedLearnpathData.name : get('title', topicData)
  },
)(cachedKeyCreator)

export const universalLectureIsLinearSelector = createCachedSelector<
  any,
  any,
  any,
  boolean
>(
  [assignedLearnpathIsLinearSelector, passProps],
  (learnpathIsLinear, { learnpathId }) =>
    learnpathId ? learnpathIsLinear : false,
)(cachedKeyCreator)

export const universalLectureDurationSelector = createCachedSelector(
  [assignedLearnpathDurationSelector, passProps],
  (learnpathDuration, { learnpathId, topicData }) =>
    learnpathId ? learnpathDuration : get('duration', topicData),
)(cachedKeyCreator)

export const universalLectureMotifIsMandatorySelector = createCachedSelector(
  [assignedLearnpathIsMandatorySelector, passProps],
  (learnpathIsMandatory, { learnpathId }) =>
    learnpathId ? learnpathIsMandatory : false,
)(cachedKeyCreator)

export const universalLectureListSelector = createCachedSelector(
  [assignedElementListSelector, passProps],
  (assignedElements, { learnpathId, topicData }) =>
    learnpathId ? assignedElements : get('lectures', topicData),
)(cachedKeyCreator)

export const universalLectureListSizeSelector = createSelector(
  universalLectureListSelector,
  size,
)

export const universalLectureSelectors = {} as ParametricResult
universalLectureSelectors.root = createCachedSelector(
  [
    lectureSelectors.root,
    assignedLectureSelectors.root,
    assignedElementSelectors.root,
    passProps,
  ],
  (
    lecture,
    assignedLecture,
    assignedElement: any,
    { topic, elementId, lecture: lectureSlug },
  ) => {
    if (elementId) {
      if (topic && assignedElement?.data?.topic && lectureSlug) {
        return mergeState(
          omit(['activeStatus', 'video_playing', 'data.progress'], lecture),
          assignedLecture,
        )
      }

      return assignedElement
    }

    return lecture
  },
)(cachedKeyCreator)

universalLectureSelectors.data = createSelector(
  universalLectureSelectors.root,
  getOr({}, 'data'),
)

universalLectureSelectors.meta = createSelector(
  universalLectureSelectors.root,
  getOr({}, 'meta'),
)

export const universalLectureVideoPlayingSelector = createSelector(
  universalLectureSelectors.root,
  getOr(false, 'video_playing'),
)

export const universalLectureDataByIdentifierSelector =
  createMemoizeByIdentifierSelector({ identifiers: ['slug', 'uid', 'id'] })(
    universalLectureSelectors.data,
    identity,
  )

export const universalLectureIsEmptySelector = createSelector(
  universalLectureSelectors.data,
  isEmpty,
)

export const universalLectureProgressSelector = createSelector(
  universalLectureSelectors.data,
  get('progress'),
)

export const universalLectureProgressFetchingSelector = createSelector(
  [rootSelector, assignedLearnpathsRootSelector, passProps],
  (
    lecturesRoot,
    assignedLearnpathsRoot,
    { learnpathId, elementId, topic, lecture },
  ) =>
    learnpathId
      ? getOr(
          false,
          [
            buildUniversalLectureApiUrl(
              { learnpathId, elementId, topic, lecture },
              {
                suffix: 'progress',
                withContext: true,
              },
            ),
            'fetching',
          ],
          assignedLearnpathsRoot,
        )
      : getOr(
          false,
          [
            buildUniversalLectureApiUrl(
              { topic, lecture },
              {
                suffix: 'progress',
              },
            ),
            'fetching',
          ],
          lecturesRoot,
        ),
)

export const universalLectureProgressStatusSelector = createSelector(
  universalLectureProgressSelector,
  get('status'),
)

export const universalLectureProgressScrollSelector = createSelector(
  universalLectureProgressSelector,
  (progress) =>
    get('status', progress) === 'completed'
      ? 1
      : getOr(0, 'meta.scroll', progress),
)

export const universalLectureProgressMetaSelector = createSelector(
  universalLectureProgressSelector,
  (progress) => get('meta', progress) ?? {},
)

export const universalLectureProgressMetaPlayedSelector = createSelector(
  universalLectureProgressMetaSelector,
  get('played'),
)

export const universalLectureProgressMetaPlayedSecondsSelector = createSelector(
  universalLectureProgressMetaSelector,
  get('playedSeconds'),
)

export const universalLectureActiveStatusSelector = createSelector(
  [universalLectureSelectors.root, universalLectureProgressStatusSelector],
  (meta, progressStatus) =>
    silentAttempt(() => localStorage.lectureState) ||
    meta.activeStatus ||
    progressStatus,
)

// Learnpath lectures do not support quizzes by now.
export const universalLectureHasQuizSelector = createSelector(
  universalLectureSelectors.data,
  get('has_quiz'),
)

export const universalLectureIndexSelector = createSelector(
  [universalLectureListSelector, passProps],
  (lectures, { match: { params }, elementId }) => {
    if (elementId) {
      return findIndex({ id: elementId }, lectures)
    }

    return findIndex({ slug: params.lecture }, lectures)
  },
)

export const universalLectureUrlSelector = createSelector(
  universalLectureSelectors.data,
  get('url'),
)

export const universalLectureImageIdSelector = createSelector(
  universalLectureSelectors.data,
  ({ image_id, thumbnail_id, type }) => {
    const imageId = image_id ?? thumbnail_id
    return getOr(imageId, type, {
      link: LECTURE_IMAGE_ID_LINK,
      file: LECTURE_IMAGE_ID_FILE,
      scorm: LECTURE_IMAGE_ID_SCORM,
      event: LECTURE_IMAGE_ID_EVENT,
    } satisfies Partial<Record<UniversalLectureType, string>>)
  },
)

export const universalLectureIdSelector = createSelector(
  universalLectureSelectors.data,
  get('id'),
)

export const universalLectureTitleSelector = createSelector(
  universalLectureSelectors.data,
  get('title'),
)

export const universalLectureVideoUrlSelector = createSelector(
  universalLectureSelectors.data,
  (data) =>
    data.type === 'embedded_video'
      ? `https://www.youtube.com/watch?v=${data.video_id}`
      : get('video_url', data),
)

export const universalLectureTypeSelector = createSelector(
  universalLectureSelectors.data,
  getOr('', 'type'),
)

export const universalLectureVideoLectureStartedSelector = createSelector(
  [universalLectureTypeSelector, universalLectureActiveStatusSelector],
  (type, activeStatus) =>
    everyTrue([
      type === LECTURE_TYPE_VIDEO || type === LECTURE_TYPE_EMBEDDED_VIDEO,
      includes(activeStatus, [STATUS_STARTED, STATUS_IN_PROGRESS]),
    ]),
)

export const universalLectureTextLectureStartedSelector = createSelector(
  [universalLectureTypeSelector, universalLectureActiveStatusSelector],
  (type, activeStatus) =>
    everyTrue([
      type === LECTURE_TYPE_TEXT,
      includes(activeStatus, [STATUS_STARTED, STATUS_IN_PROGRESS]),
    ]),
)

export const universalNextLectureUrlSelector = createSelector(
  [nextLectureUrlSelector, assignedNextElementUrlSelector, passProps],
  (nextLectureUrl, assignedNextElementUrl, { learnpathId }) =>
    learnpathId ? assignedNextElementUrl : nextLectureUrl,
)

export const universalLectureActiveTranscriptTimestampSelector = createSelector(
  [
    lectureTranscriptSelector,
    universalLectureProgressMetaPlayedSecondsSelector,
  ],
  (transcript: any[], playedSeconds) =>
    pipe([
      map(({ timestamp }) => {
        const [minutes, seconds] = map(toNumber, split(':', timestamp))

        return {
          timestamp,
          seconds: minutes * 60 + seconds,
        }
      }),
      filter(({ seconds }) => seconds < playedSeconds),
      (selected) => last(selected) || first(transcript),
      get('timestamp'),
    ])(transcript),
)

export const universalLectureStatusMapSelector = createSelector<
  any,
  any,
  any,
  {
    learnpathId?: string
    elementId?: string
    topic?: string
    slugs?: string[]
  }
>(
  [rootSelector, assignedLearnpathsRootSelector, passProps],
  (
    root,
    assignedLearnpathRoot,
    {
      learnpathId,
      elementId,
      topic,
      slugs,
    }: { learnpathId?; elementId?; topic?; slugs? } = {},
  ) =>
    pipe([
      map((lectureSlug: string) => ({
        [lectureSlug]: get(
          [
            learnpathId
              ? buildLectureLink({
                  learnpathId,
                  elementId,
                  topic,
                  lecture: lectureSlug,
                })
              : buildUniversalLectureApiUrl(
                  toMatchParams({
                    topic,
                    lecture: lectureSlug,
                  }),
                ),
            'data',
            'progress',
            'status',
          ],
          learnpathId ? assignedLearnpathRoot : root,
        ),
      })),
      mergeAll,
      defaultTo({}),
    ])(slugs),
)
